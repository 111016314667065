<template>
  <v-container fluid class="min-height">
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            Alarmas
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>
